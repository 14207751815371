
const toggleBtn = document.querySelector('.toggle-btn');
const header = document.querySelector('header');

const initializeMobileMenu = () => {

    toggleBtn?.addEventListener('click', () => {

        header?.classList.toggle('open');
    })
}

export default initializeMobileMenu
