
import Glide from '@glidejs/glide';
import LightBox from './lightbox/lightbox'

export const getGalleryImages = () => {

    const galleryImages = document.querySelectorAll('.gallery-container .photo img') as NodeListOf<HTMLImageElement>;

    galleryImages.forEach(image => {

        image.addEventListener('click', (e) => {

            LightBox.show(image.src);
        })
    })
}

export const initializeGallery = () => {

    new Glide('.gallery-glide', {
        type: 'carousel',
        autoplay: 5000,
        hoverpause: true,
        perView: 1,
    }).mount()
}

export default {
    getGalleryImages,
    initializeGallery
}
