
export const validateFieldValue = (fieldName: string, fieldValue: string, rules: any) => {

    let isValid = true;
    let errorMessage = '';

    if (rules.required) {
        isValid = fieldValue.trim() !== '';
        errorMessage = `El campo ${fieldName} es obligatorio`;
    }

    if (rules.minLength) {
        isValid = fieldValue.length >= rules.minLength;
        errorMessage = `El campo debe tener al menos ${rules.minLength} caracteres`;
    }

    return { isValid, errorMessage }
}
