
import Glide from '@glidejs/glide'

import './glide/glide.scss'

const initializeSlider = () => {

    new Glide('.slider-glide', {
        type: 'carousel',
        autoplay: 5000,
        hoverpause: true,
        perView: 1,
        // breakpoints: {
        //     425: {
        //         perView: 1
        //     }
        // }
    }).mount()
}

export default initializeSlider
