
import { baseUrl } from "./globals";

import axios from 'axios';

import { validateFieldValue } from "./form-validator";

const btnSubmit = document.querySelector('.btn-submit') as HTMLButtonElement;

const namesInput = document.getElementById('form1-names') as HTMLInputElement;
const surNamesInput = document.getElementById('form1-surnames') as HTMLInputElement;
const emailInput = document.getElementById('form1-email') as HTMLInputElement;
const phoneInput = document.getElementById('form1-phone') as HTMLInputElement;
const commentsInput = document.getElementById('form1-comments') as HTMLTextAreaElement;
const permissionChecked = document.getElementById('form1-checkbox') as HTMLInputElement;

const resetForm = () => {

    namesInput.value = '';
    surNamesInput.value = '';
    emailInput.value = '';
    phoneInput.value = '';
    commentsInput.value = '';
    permissionChecked.checked = false;

    btnSubmit.innerHTML = 'Enviar'
}

const getFormData = () => {

    return {
        names: namesInput.value,
        surnames: surNamesInput.value,
        email: emailInput.value,
        phone: phoneInput.value,
        messages: commentsInput.value,
        permission: permissionChecked.checked
    }
}

const validateForm = () => {

    const data = getFormData()

    const validName = validateFieldValue('Nombres', data.names, { required: true})
    const validSurname = validateFieldValue('Apellidos', data.surnames, { required: true})
    const validEmail = validateFieldValue('Email', data.email, { required: true})
    const validPhone = validateFieldValue('Teléfono', data.phone, { required: true})

    if (!validName.isValid) {

        alert(validName.errorMessage)
        return false;
    }

    if (!validSurname.isValid) {

        alert(validSurname.errorMessage)
        return false;
    }

    if (!validEmail.isValid) {

        alert(validEmail.errorMessage)
        return false;
    }

    if (!validPhone.isValid) {

        alert(validPhone.errorMessage)
        return false;
    }

    return true
}

const submitForm = async () => {

    btnSubmit.innerHTML = 'Enviando...'

    const data = getFormData()

    const res: any = await axios.post(baseUrl + 'index.php/welcome/contact', data)

    if (res.data.success) {

        btnSubmit.innerHTML = 'Enviado'

        setTimeout(() => {

            alert('Su mensaje ha sido enviado')
            resetForm()
        }, 100)
    }
}

const setupForm = () => {

    btnSubmit.addEventListener('click', (e) => {

        if (validateForm()) {

            submitForm()
        }
    })
}

const initSliderForm = () => {

    setupForm()
}

export default initSliderForm
