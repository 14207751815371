
import './lightbox.scss'


const closeLightBox = () => {

    const modal = document.querySelector('.lightbox') as HTMLDivElement;

    modal.classList.remove('show');

    document.body.classList.remove('lightbox-open');
    document.body.removeChild(modal);
}

export const openLightBox = (src: string) => {

    setupLightBox()

    const modal = document.querySelector('.lightbox') as HTMLDivElement;

    modal.classList.add('show');
    document.body.classList.add('lightbox-open');

    const img = document.createElement('img');
    img.classList.add('lightbox__image');
    img.src = src;
    modal.appendChild(img);
}

const setupLightBox = () => {

    const modal = document.createElement('div');
    modal.classList.add('lightbox');

    const closeButton = document.createElement('button');
    closeButton.classList.add('lightbox__close');
    closeButton.innerHTML = '&times;';
    closeButton.addEventListener('click', closeLightBox);

    modal.appendChild(closeButton);
    document.body.appendChild(modal);
}



export default {
    show: openLightBox,
}
