
import { baseUrl } from "./globals";

const mainFrame = document.getElementById('parcelario-frame');
const moreInfoWindow = document.createElement('div');
const title = document.createElement('h3')
const img: HTMLImageElement = document.createElement('img');

const createHeader = () => {

    const windowHeader = document.createElement('div');
    windowHeader.classList.add('window-header');

    title.classList.add('window-title');
    title.innerText = 'Información';

    const closeBtn = document.createElement('button');
    closeBtn.classList.add('close-btn');
    closeBtn.innerHTML = `<img src="${baseUrl}static/img/x.svg" width="20">`;
    closeBtn.addEventListener('click', closeMoreInfoWindow);

    windowHeader.appendChild(title)
    windowHeader.appendChild(closeBtn)

    return windowHeader
}

const createBody = () => {

    const body = document.createElement('div');
    body.classList.add('window-body');

    img.classList.add('more-info-img');

    body.appendChild(img);

    return body
}

const setupMoreInfoWindow = () => {

    moreInfoWindow.classList.add('more-info-window');

    const windowHeader = createHeader()
    const windowBody = createBody()

    moreInfoWindow.appendChild(windowHeader)
    moreInfoWindow.appendChild(windowBody)
}

const openMoreInfoWindow = (image: string, description: string) => {

    title.innerText = description;
    img.src = image

    moreInfoWindow.classList.add('open');
}

const closeMoreInfoWindow = () => {

    moreInfoWindow.classList.remove('open');
}

const initializeMarkers = () => {

    const markers: any = Array.from(document.getElementsByClassName('map-marker'));

    markers.forEach((marker: any) => {

        const position = marker.attributes.getNamedItem('data-position').value.split('-');

        const img = marker.querySelector('img.point-image');
        const description = marker.querySelector('span').innerText;

        marker.style.top = `calc(${position[1]}% - 42px)`;
        marker.style.left = `calc(${position[0]}% - 18px)`;

        marker.addEventListener('click', (e: any) => {


            openMoreInfoWindow(img.attributes.getNamedItem('src').value, description)
        })
    })
}

const initializeList = () => {

    const list: any = Array.from(document.getElementsByClassName('list-item'));

    list.forEach((item: any) => {

        const img = item.querySelector('img');
        const description = item.querySelector('div').innerText;

        item.addEventListener('click', (e: any) => {

            openMoreInfoWindow(img.attributes.getNamedItem('src').value, description)
        })
    })
}

export const initParcelario = () => {

    initializeMarkers()
    initializeList()

    setupMoreInfoWindow()

    mainFrame?.appendChild(moreInfoWindow);
}


