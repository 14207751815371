
import { initParcelario } from './parcelario';

import initSliderForm from './contact-form';
import initFooterForm from './contact-form-2';

import initializeMobileMenu from './header';


import { getGalleryImages, initializeGallery } from './gallery';
import initializeOldProjects from './old-projects'
import initializeSlider from './slider';

initializeMobileMenu()

initParcelario()

initSliderForm()
initFooterForm()

getGalleryImages()
initializeGallery()

document.querySelectorAll('.menu li a').forEach(el => {
    el.addEventListener('click', () => {

        document.querySelector('header')?.classList.remove('open')
    })
})

initializeOldProjects()
initializeSlider()

