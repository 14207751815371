
import Glide from '@glidejs/glide'

import './glide/glide.scss'

const initializeOldProjects = () => {

    // const windowWidth = window.innerWidth

    // if (windowWidth <= 425) {

        new Glide('.old-projects-glide', {
            type: 'carousel',
            autoplay: 0,
            hoverpause: true,
            perView: 4,
            breakpoints: {
                425: {
                    perView: 1,
                    autoplay: 3000
                }
            }
        }).mount()
    // }
}

export default initializeOldProjects
